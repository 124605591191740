import React, { useEffect, useContext, useRef } from "react";
import gsap from 'gsap';
import { ModalWrapper } from "./SubmitResumeModal.styled";
import { GlobalDispatchContext } from "../../../../src/context/GlobalContextProvider";
import BottomLine from "../../BottomLine/BottomLine";
import closeButton from "../../../images/close-btn.svg";

const SubmitResumeModal = ({ content }) => {

  const formRef = useRef();
  const dispatch = useContext(GlobalDispatchContext);
  const modalWrapper = React.useRef();
  const RECAPTCHA_SITE_KEY = '6Lc9XNUoAAAAADFYC9DoqVQFEpIEFgLCLKjtB_GF';

  const $ = gsap.utils.selector(modalWrapper);

  useEffect(() => {
    // Add reCAPTCHA script
    const script = document.createElement("script");
    script.src = `https://www.google.com/recaptcha/api.js?render=${RECAPTCHA_SITE_KEY}`;
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };


  }, []);

  const closeModal = (e) => {
    if (!e.target.classList.contains('close-trigger')) return;
    let tl = new gsap.timeline();
    tl.to($('.modal-box'), { opacity: 0, duration: 0.2 });
    tl.to(modalWrapper.current, {
      opacity: 0, duration: 0.2, onComplete: () => {
        dispatch({ type: 'MODAL_CONTENT', payload: null });
        document.body.classList.remove('modal-open');
      }
    }, "+=0.0");
  };

  const encode = (data) => {
    const formData = new FormData();
    Object.keys(data).forEach((k) => {
      formData.append(k, data[k]);
    });
    return formData;
  };

  const handleSubmitWithRecaptcha = async (e) => {
    e.preventDefault();
    const formData = new FormData(formRef.current);
  
    window.grecaptcha.ready(async () => {
      const token = await window.grecaptcha.execute(RECAPTCHA_SITE_KEY, { action: 'submit' });
  
      // Submit the token to your server for verification
      fetch("/.netlify/functions/verify-recaptcha", {
        method: "POST",
        body: JSON.stringify({ "g-recaptcha-response": token }),
        headers: { "Content-Type": "application/json" },
      }).then(async (response) => {
        if (response.ok) {
          // handle successful reCAPTCHA verification
          // Submit your form or whatever action you need
          fetch("/", {
            method: "POST",
            body: encode(Object.fromEntries(formData)),
          }).then(() => {
            showMessage('Success! Thanks for sending us your resume.');
          }).catch(error => {
            showMessage(`An error has occurred [${error}]. Try again later.`);
          });
        } else {
          // handle failed reCAPTCHA verification
          showMessage('Failed reCAPTCHA verification');
        }
      });
    });
  };  

  const showMessage = (msg) => {
    gsap.set($('.message p'), { innerHTML: msg });

    new gsap.timeline()
      .to($('.scrollable, header'), { duration: 0.2, opacity: 0 })
      .set($('.message'), { opacity: 0, display: 'flex' })
      .to($('.message'), { duration: 0.2, opacity: 1 });
  };

  return (
    <ModalWrapper bgcolor={content.bgcolor} className="close-trigger" ref={modalWrapper} onClick={closeModal}>
      <button className="btn-close close-trigger" onClick={closeModal}>
        <img src={closeButton} alt="" />
      </button>
      <div className="modal-box">
        <div className="text-wrap">
          <header>
            <h3>{content.title}</h3>
            <BottomLine />
          </header>
          <div className="scrollable">
            <p>{content.mainCopy}</p>

            <form 
                ref={formRef}
                data-netlify="true"
                data-netlify-honeypot="bot-field"
                name="resumes"
                method="post"
                action="#"
                onSubmit={handleSubmitWithRecaptcha}
            >
              <input type="hidden" name="form-name" value="resumes" />
              <label className="name">
                <span>Name:</span>
                <input type="text" required name="name" />
              </label>
              <label className="position">
                <span>Applying to:</span>
                <input type="text" required name="position" defaultValue={content.jobTitle} />
              </label>
              <label>
                <input id="resume-file" name="file" type="file" required accept=".doc, .docx, .pdf" />
              </label>
              <button type="submit" className="submit">Submit</button>
            </form>

          </div>
          <div className="message">
            <p>After Submission Message</p>
            <button className="close-trigger" onClick={closeModal} onKeyDown={closeModal}>Close this modal</button>
          </div>
        </div>
      </div>
    </ModalWrapper>
  );
};

export default SubmitResumeModal;