import tw, { styled } from "twin.macro"

export const ModalWrapper = styled.div`
    ${tw`fixed bg-modalgray w-full flex justify-center items-center px-6 box-border z-20`}
    height: 90vh;

    .btn-close{
        ${tw`absolute m-0 p-0 right-5 top-5 bg-transparent outline-none border-none cursor-pointer`}
        img{
            pointer-events: none;
        }
    }

    .modal-box{
        ${tw`bg-white rounded-lg overflow-hidden flex flex-col`}
        box-shadow: 0 6px 18px rgb(0 0 0 / 30%);
        max-width: 680px;
        max-height: 580px;
        height: 70vh;

        header{
            ${tw`relative flex flex-col justify-center items-center py-4`}
            h3{
                ${tw`text-center px-8 m-0 capitalize text-3xl sm:text-5xl font-medium`}
            }
            .bottomline{
                height: 4px;
            }
        }

        .text-wrap{
            ${tw`overflow-auto flex flex-col h-full relative text-darkgray text-xl`}

            .scrollable{
                ${tw`h-full overflow-auto px-4 pb-4 sm:px-8 sm:pb-8 h-full`}

                p{
                    ${tw`font-thin text-xl mt-8 mb-4 mt-8`}
                }

            }

            .message{
                ${tw`h-full w-full p-4 flex flex-col justify-center items-center hidden opacity-0 absolute inset-0 box-border text-darkgray text-xl`}

                button{
                    ${tw`
                        mx-auto mt-10 sm:mt-20
                        uppercase
                        block bg-transparent border-lightorange text-lightorange  hover:bg-lightorange hover:text-white  transition-all cursor-pointer
                        w-max text-center border-0 border-8 border-solid  px-8 py-3 rounded-full font-bold no-underline
                    `}
                }
            }
        }

        form{

            label{
                ${tw`text-xl flex flex-row h-full text-darkgray mt-4`}
                span{ ${tw`whitespace-nowrap text-lg`} }
                input{ ${tw`inline-block w-full border-0 border-b-2 border-b-gray box-border text-lg text-orange outline-none`} }
            }


            input{
                ${tw`block mx-auto`}

                &[type=file]{
                    ${tw`bg-lightgray p-4 mt-4 w-full text-darkgray text-base`}
                }

                &[type=file]::file-selector-button{
                    ${tw`outline-none bg-gray border-none rounded-full py-2 px-4 text-white text-sm font-bold w-max cursor-pointer`}
                }

                &::selection {
                    color: ${tw`text-darkgray`};
                }
                
                /* For compatibility with Firefox */
                &::-moz-selection {
                    color: ${tw`text-darkgray`};
                }
                
            }

            .submit{
                ${tw`
                    mx-auto mt-8
                    uppercase
                    block bg-transparent border-lightorange text-lightorange  hover:bg-lightorange hover:text-white  transition-all cursor-pointer
                    w-max text-center border-0 border-8 border-solid  px-8 py-3 rounded-full font-bold no-underline
                `}
                max-width: 240px;
                width: 100%;
            }
        }
    }
`