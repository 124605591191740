import tw, { styled } from "twin.macro"
import { GatsbyImage } from "gatsby-plugin-image"

export const Wrapper = styled.div`
    ${tw`relative`}

    .bottomline{
        ${tw`w-full h-2 absolute bottom-0 left-0`}    
    }
`


export const DesktopImage = styled(GatsbyImage)`
    ${tw`hidden sm:block`}
`

export const BlockContent = styled.div`
  ${tw`
    hidden sm:flex flex-col items-start justify-start text-white
    bg-cover bg-center
  `}

  .content-area{
    ${tw`absolute inset-0 flex flex-col items-center justify-center w-full h-full`}

    h2{
        ${tw`text-5xl uppercase text-center font-bold`}
        text-shadow: 0px 0px 10px rgba(0,0,0,0.5);
    }

    .boxed{

      ${tw`w-full bg-darkglass`}

      .restricted-width-area{
        ${tw`max-w-screen-xl mx-auto px-4 sm:px-8 xl:px-0 box-border flex items-center py-8`}
      }

      & p{
        ${tw`mb-12 w-full`}
      }
      
    }

  }
  
`


export const MobileImage = styled.div`
    ${tw`relative`}

    h2{
        ${tw`text-4xl uppercase text-center font-bold`}
        text-shadow: 0px 0px 10px rgba(0,0,0,0.5);
    }
`

export const BlockContentMobile = styled.div`
  ${tw`relative flex sm:hidden flex-col items-center justify-center w-full h-full text-white`}

  .content-area{
    ${tw`absolute inset-0 flex flex-col items-center justify-center w-full h-full`}
  }

  .boxed{
    ${tw`bg-darkgray h-full px-6 pt-5 pb-7`}
    img{
        ${tw`block mx-auto`}
    }
  }
`

