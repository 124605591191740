import * as React from "react"
import Layout from "../components/Layout/Layout"
import { Helmet } from "react-helmet"

import Seo from "../components/Seo/Seo"
import TopHero from "../components/Culture/TopHero/TopHero"
import CommunityBlock from "../components/Culture/CommunityBlock/CommunityBlock"
import CareersBlock from "../components/Culture/CareersBlock/CareersBlock"

import { useCultureQuery } from "../hooks/useCultureQueries"

// Modal imports
import CareerModal from "../components/Culture/CareerModal/CareerModal"
import SubmitResumeModal from "../components/Culture/SubmitResumeModal/SubmitResumeModal"
import {GlobalStateContext, GlobalDispatchContext } from "../../src/context/GlobalContextProvider"


const Culture = ({ children }) => {

  const data = useCultureQuery();
  const dispatch = React.useContext(GlobalDispatchContext)
  const state = React.useContext(GlobalStateContext)

  React.useEffect(() => {
    // cleanup function will be called when component is removed
    return () => {
      dispatch({
        type: 'MODAL_CONTENT',
        payload: null
      })
      document.body.classList.remove('modal-open')
    }
  },[dispatch]); 

  const getModal = (type) => {
    let modal;

    switch(type){
      case 'CAREER':        modal = <CareerModal content={state.modalContent}/>; break;
      case 'SUBMIT_RESUME': modal = <SubmitResumeModal content={state.modalContent}/>; break;
      default: modal = null
    }
    return modal
  }


  return(
    <Layout>
      <Helmet>
        <script key="recaptcha" src="https://www.google.com/recaptcha/api.js?render=6Lc9XNUoAAAAADFYC9DoqVQFEpIEFgLCLKjtB_GF" async defer></script>
      </Helmet>
      <Seo title="Culture" />
      <TopHero assets={data.wpPage.ACF_CulturePage.topHero} />
      <CommunityBlock assets={data.wpPage.ACF_CulturePage.communityBlock} />
      <CareersBlock
        assets={data.wpPage.ACF_CulturePage.careers}
        careers={data.allWpPost.edges}
        submitResumeData={data.wpPage.ACF_CulturePage.submitResume}
      />

      {state !== undefined && state.modalContent !== null 
        ? getModal(state.modalContent.type)
        : (null)
      }
    </Layout>
  )

}

export default Culture
