import { useStaticQuery, graphql } from "gatsby"

export const useCultureQuery = ()=> {

    return useStaticQuery(graphql`
    
        query Culture {

            wpPage(title: {eq: "CULTURE"}) {
                ACF_CulturePage {
                    
                    topHero {
                        animatedTitle
                        subtitle
                        mainCopy
                        desktopImage {
                            localFile {
                                publicURL
                            }
                        }
                        mobileImage {
                            localFile {
                                publicURL
                            }
                        }
                    }

                    communityBlock {
                        title
                        mainCopy
                        desktopImage {
                            localFile {
                                childImageSharp {
                                    gatsbyImageData
                                }
                            }
                        }
                        mobileImage {
                            localFile {
                                childImageSharp {
                                    gatsbyImageData
                                }
                            }
                        }
                    }

                    careers {
                        title1
                        copy1
                        title2
                        copy2
                        copy3
                    }

                    submitResume {
                        mainCopy
                        submitButtonText
                        title
                    }
                }
            }

            allWpPost(filter: {categories: {nodes: {elemMatch: {name: {eq: "Careers"}}}}}) {
                edges {
                    node {
                        title
                        content
                        ACF_careers_post_details {
                            location
                            titleColor
                            link {
                                target
                                url
                            }
                        }
                    }
                }
            }
        }
    `)
}