import React from "react"
import { Wrapper, DesktopImage, MobileImage, BlockContent, BlockContentMobile} from "./CommunityBlock.styled"
import WordCarousel from "../WordCarousel/WordCarousel"
import 'twin.macro'
import { GatsbyImage } from "gatsby-plugin-image"


//Assets
import bottomline from "../../../images/hero-bottomline.png"

const CommunityBlock = ({assets})=> {    

    return(
        <Wrapper>

            <BlockContent>
                <DesktopImage image={assets.desktopImage.localFile.childImageSharp.gatsbyImageData} alt=""/>
                <div className="content-area">
                    <h2>{assets.title}</h2>
                    <div className="boxed">
                        <div className="restricted-width-area">
                            <p>{assets.mainCopy}</p>
                            <WordCarousel/>
                        </div>
                    </div>
                </div>
            </BlockContent>

            <BlockContentMobile>
                <MobileImage>
                    <GatsbyImage image={assets.mobileImage.localFile.childImageSharp.gatsbyImageData} alt=""/>
                    <div className="content-area">
                        <h2>{assets.title}</h2>
                    </div>
                </MobileImage>

                <div className="boxed">
                    <p>{assets.mainCopy}</p>
                    <WordCarousel/>
                </div>
            </BlockContentMobile>

            <img className="bottomline" src={bottomline} alt=""/>


        </Wrapper>
    )
}

export default CommunityBlock