import React from "react"
import { Wrapper, BlockContent, BlockContentMobile} from "./TopHero.styled"
import TypeWriteLine from "../../TypeWriteLine/TypeWriteLine"
import 'twin.macro'

//Assets
import bottomline from "../../../images/hero-bottomline.png"
import chooseLogo from "../../../images/choose-logo-white.svg"


const TopHero = ({assets})=> {    
    return(
        <Wrapper>

            <BlockContent bgasset={assets.desktopImage.localFile.publicURL}>
                <div className="content-area">
                    <div className="boxed">
                        <img className="chooseLogo" src={chooseLogo} alt="Choose"/>
                        <TypeWriteLine copy={assets.animatedTitle}/>
                        <h3>{assets.subtitle}</h3>
                        <p>{assets.mainCopy}</p>
                    </div>
                </div>
            </BlockContent>

            <BlockContentMobile>
                <div className="image-wrapper">
                    <img src={assets.mobileImage.localFile.publicURL} alt=""/>

                    <div className="boxed">
                        <img className="chooseLogo" src={chooseLogo} alt="Choose"/>
                        <TypeWriteLine copy={assets.animatedTitle}/>
                    </div>
                </div>
                
                <div className="text-wrapper">
                    <h3>{assets.subtitle}</h3>
                    <p>{assets.mainCopy}</p>
                </div>
            </BlockContentMobile>


            <img className="bottomline" src={bottomline} alt=""/>
        </Wrapper>
    )
}

export default TopHero