import React, {useContext, useEffect, useRef} from "react";
import BottomLine from "../../BottomLine/BottomLine"
import {ModalWrapper} from "./CareerModal.styled"
import {GlobalDispatchContext } from "../../../../src/context/GlobalContextProvider"
import gsap from 'gsap';
import { useCultureQuery } from "../../../hooks/useCultureQueries"


import closeButton from "../../../images/close-btn.svg"


const CareerModal = ({content}) => {

    const dispatch = useContext(GlobalDispatchContext);
    const modalWrapper = useRef();
    const $ = gsap.utils.selector(modalWrapper);
    const data = useCultureQuery();


    const closeModal = (e)=> {
        if(!e.target.classList.contains('close-trigger')) return;

        let tl = new gsap.timeline()
        tl.to($('.modal-box'), {opacity:0, duration:0.2})
        tl.to(modalWrapper.current, {opacity:0, duration:0.2, onComplete:()=>{
            dispatch({type: 'MODAL_CONTENT', payload: null})
            document.body.classList.remove('modal-open')
        }}, "+=0.0")
    }

    const openSubmitResumeModal = (item, jobTitle=null)=> {
        let dispatchObj = {
            type: 'MODAL_CONTENT',
            payload: {
                type: 'SUBMIT_RESUME',
                title: item.title,
                mainCopy: item.mainCopy,
            }
        }

        if(jobTitle){
            dispatchObj.payload.jobTitle = jobTitle
        }

        dispatch(dispatchObj)
    }

    useEffect(() => {
        document.body.classList.add('modal-open')

        //Animation
        let tl = new gsap.timeline()
        tl.from(modalWrapper.current, {opacity:0, duration:0.2})
        tl.from($('.modal-box'), {opacity:0, duration:0.2})
        tl.from($('header'), {opacity:0}, "-=0.1")
        tl.from($('.text-wrap'), {opacity:0}, "-=0.2")

        // cleanup function will be called when component is removed
        return () => {
        }
  
    }, [$]);

    return(
        <ModalWrapper bgcolor={content.bgcolor} className="close-trigger" ref={modalWrapper} onClick={closeModal}>

            <button className="btn-close close-trigger" onClick={closeModal}>
                <img src={closeButton} alt=""/>
            </button>

            <div className="modal-box">
                <div className="text-wrap">
                    <header>
                        <button onClick={closeModal} onKeyDown={closeModal} className="close-trigger">BACK TO LISTINGS</button>
                        <h3>{content.title}</h3>
                        <h4>{content.location}</h4>
                        <BottomLine/>
                    </header>
                    <div className="scrollable" dangerouslySetInnerHTML={{ __html: content.description }}></div>
                    {
                        content.link 
                            ? <a href={content.link.url} target={content.link.target}>APPLY</a>
                            : <button onClick={ () => { 
                                    openSubmitResumeModal(data.wpPage.ACF_CulturePage.submitResume, content.title) 
                                }}
                              >UPLOAD RESUME</button>
                    }
                </div>
            </div>
        </ModalWrapper>
    )
}

export default CareerModal;