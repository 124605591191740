import React, {useContext} from "react"
import { GlobalDispatchContext } from "../../../context/GlobalContextProvider"
import { Wrapper, BlockContent, CareerBox} from "./CareersBlock.styled"
import 'twin.macro'

//Assets
import bottomline from "../../../images/hero-bottomline.png"

const CareersBlock = ({assets, careers, submitResumeData})=> { 
    
    const dispatch = useContext(GlobalDispatchContext)

    const openModal = (item)=> {
        dispatch({
            type: 'MODAL_CONTENT',
            payload: {
                type: 'CAREER',
                title: item.node.title,
                location: item.node.ACF_careers_post_details.location,
                description: item.node.content,
                bgcolor: item.node.ACF_careers_post_details.titleColor,
                link: item.node.ACF_careers_post_details.link
            }
        })
    }

    const openSubmitResumeModal = (item, jobTitle=null)=> {
        let dispatchObj = {
            type: 'MODAL_CONTENT',
            payload: {
                type: 'SUBMIT_RESUME',
                title: item.title,
                mainCopy: item.mainCopy,
            }
        }

        dispatch(dispatchObj)
    }


    return(
        <Wrapper>
            <BlockContent>
                <div className="content-area">
                    <h2>{assets.title1}</h2>
                    <div className="ctnt" dangerouslySetInnerHTML={{ __html: assets.copy1 }} />
                    <h2>{assets.title2}</h2>
                    <p>{assets.copy2}</p>
                    <div className="careers-grid">
                        {careers.map((item, index)=>{
                            return(
                                <CareerBox bgcolor={item.node.ACF_careers_post_details.titleColor} key={index} className="career-box">
                                    <h3>{item.node.title}</h3>
                                    <p>{item.node.ACF_careers_post_details.location}</p>
                                    <button onClick={ ()=> openModal(item) } onKeyDown={ ()=> openModal(item) }>VIEW DESCRIPTION</button>
                                </CareerBox>
                            )
                        })}
                    </div>

                    <p tw="mt-14">{assets.copy3}</p>

                    <button 
                        onClick={ ()=> openSubmitResumeModal(submitResumeData) }
                        onKeyDown={ ()=> openSubmitResumeModal(submitResumeData) }
                        className="btn-send-resume"
                    >
                            SEND RESUME
                    </button>
                </div>
            </BlockContent>
            <img className="bottomline" src={bottomline} alt=""/>
        </Wrapper>
    )
}

export default CareersBlock