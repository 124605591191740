import tw, { styled } from "twin.macro"

export const Wrapper = styled.div`
    ${tw`relative`}

    .bottomline{
        ${tw`w-full h-2 absolute bottom-0 left-0`}    
    }
`

export const BlockContent = styled.div`
  ${tw`
    hidden sm:flex flex-col items-start justify-start text-white
    bg-cover bg-center
  `}
  background-image: ${({ bgasset }) => `url(${bgasset})`};

  .content-area{
    ${tw`flex items-start w-full max-w-screen-xl mx-auto px-4 sm:px-8 xl:px-0 box-border`}
  }
  
  .boxed{
      ${tw`bg-darkgray h-full px-6 pt-5 lg:pt-10`}
      max-width: 480px;

      .chooseLogo{
          ${tw`block`}
      }

      h2{
        ${tw`text-4xl`}
        /*.cursor{ height:70%; }*/
      }

      h3{
        ${tw`uppercase text-2xl md:text-3xl lg:text-4xl my-2`}
      }
      p{
        ${tw`mb-12`}
      }

  }
`

export const BlockContentMobile = styled.div`
  ${tw`relative flex sm:hidden flex-col items-center justify-center w-full h-full text-white`}

  .image-wrapper{

    ${tw`relative`}
    font-size: 0;

    img{ ${tw`w-full`} }

    .boxed{
      ${tw`absolute inset-0 flex flex-col items-center justify-start w-full py-4`}

      .chooseLogo{
        ${tw`block`}
        width: 11rem;
        max-width: 168px;

      }
      h2{
        ${tw`block`}
      }

    } 
  }
  .text-wrapper{
    ${tw`relative bg-darkgray px-4 py-8`}

    h3{
        ${tw`uppercase text-2xl md:text-3xl lg:text-4xl my-0`}
    }
  }
`