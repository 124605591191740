import tw, { styled } from "twin.macro"

export const Wrapper = styled.div`
    ${tw`block relative overflow-hidden`}
    min-width: 164px;


    .main-circle{
     ${tw`relative z-10`}
    }

    .img-wrapper{
        ${tw`overflow-hidden`}
    }

    .words-wrapper{
        ${tw`absolute inset-0 bottom-auto flex flex-col justify-start items-center text-center`}

        p{
            ${tw`uppercase font-bold m-0 text-darkgray`}
            margin: 10px 0 !important;
            font-size: 28px;
            line-height: 30px;
            opacity:0;
            &:nth-of-type(3){
                ${tw`text-white opacity-100`}
            }
        }
    }

`