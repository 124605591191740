import tw, { styled } from "twin.macro"

export const Wrapper = styled.div`
    ${tw`relative bg-lightgray`}

    .bottomline{
        ${tw`w-full h-2 absolute bottom-0 left-0`}    
    }
`

export const BlockContent = styled.div`
  ${tw`flex flex-col text-white`}

  .content-area{
    ${tw`max-w-screen-xl w-full mx-auto px-4 sm:px-8 xl:px-0 box-border pt-8 pb-16`}

    h2{
      ${tw`text-left text-lightorange text-4xl font-bold sm:text-center uppercase`}
    }

    & > p{
      ${tw`text-left text-darkgray sm:text-center mx-auto`}
      max-width: 740px;
    }

    .ctnt {
      ${tw`text-left text-darkgray sm:text-center mx-auto`}
      max-width: 740px;
    }

    .careers-grid{
      ${tw`
        grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 justify-between
        lg:flex justify-center
      `}

      a{
        ${tw`
          uppercase text-center border-0 border-8 border-solid border-white px-8 py-1 rounded-full font-bold no-underline text-white mx-4 my-2
          bg-lightorange hover:bg-white hover:text-lightorange transition
        `}
        min-width: 190px;
      }
    }
  }

  .btn-send-resume{
    ${tw`
        block bg-transparent border-lightorange text-orange  hover:bg-lightorange hover:text-white  transition-all cursor-pointer
        w-max text-center border-0 border-8 border-solid  px-8 py-1 rounded-full font-bold no-underline  mx-auto mt-4 md:mt-10
    `}
  }
`

export const CareerBox = styled.div`
  ${tw`bg-white text-center`}
  box-shadow: 2px 2px 10px rgb(0 0 0 / 25%);
  min-width: 20rem;

  h3{
    ${tw`text-center uppercase font-medium text-base py-2`}
    background-color: ${({ bgcolor }) => bgcolor };
  }
  p{
    ${tw`text-center text-darkgray uppercase font-medium text-base`}
  }
  button{
    ${tw`text-white outline-none border-none rounded-full px-4 py-2 mt-8 mb-6 cursor-pointer`}
    background-color: ${({ bgcolor }) => bgcolor };
  }
`