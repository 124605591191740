import tw, { styled } from "twin.macro"

export const ModalWrapper = styled.div`
    ${tw`fixed bg-modalgray w-full flex justify-center items-center px-6 box-border`}
    height: 90vh;

    .btn-close{
        ${tw`absolute m-0 p-0 right-5 top-5 bg-transparent outline-none border-none cursor-pointer`}
        img{
            pointer-events: none;
        }
    }

    .modal-box{
        ${tw`bg-white rounded-lg overflow-hidden flex flex-col`}
        box-shadow: 0 6px 18px rgb(0 0 0 / 30%);
        max-width: 680px;
        max-height: 580px;
        height: 70vh;

        header{
            ${tw`relative flex flex-col justify-center items-center py-4`}
            h3{
                ${tw`text-center px-8 mt-3 mb-0 capitalize text-5xl font-medium`}
            }
            h4{
                ${tw`text-center px-8 mt-3 mb-0 capitalize text-2xl font-light`}
            }
            .bottomline{
                height: 4px;
            }
        }

        .text-wrap{
            ${tw`overflow-auto flex flex-col`}

            .scrollable{
                ${tw`h-full overflow-auto mb-4 px-8`}

                p, li{
                    ${tw`font-thin text-xl mt-4 mb-0`}
                }
            }

            button, a{
                ${tw`outline-none border-none rounded-full py-2 px-4 text-white text-sm font-bold w-max cursor-pointer`}
                background-color: ${({ bgcolor }) => bgcolor };

                ${tw`text-center mx-auto mb-6 no-underline`}
                min-width: 8rem;
            }
        }
    }
`